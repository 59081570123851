main {
    background-image: url(./Images/gym.jpeg);
    background-color: #000;
    background-repeat: no-repeat;
    max-width: 100%;
    min-width: 100%;
  }

body {
    position:relative;
    -webkit-tap-highlight-color: transparent;
}
a {
  color: #FFF9C5;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}
.Homepagecontentcontainer {
    background-color: transparent;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    justify-content: space-evenly;
}
.Homepagecontent1 {
    position:relative;
    display: flex;
    justify-content: center;
    max-width: 600px;
    width: 600px;
    border-radius: 100px;
    margin-top: 2%;
    margin-left: 3%;
    box-shadow: 0px 9px 30px #fff9c528;
    text-align: left;
    overflow: hidden;
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: #434343;
        background-size: contain;
        border-radius: 20px;
        opacity: 0.4;
      }
}
.Homepagecontent2 {
    position:relative;
    display: flex;
    justify-content: center;
    max-width: 800px;
    width: 800px;
    margin-top: 2%;
    text-align: center;
}
.Homepagecontent3 {
  position:relative;
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 600px;
  border-radius: 20px;
  margin-top: 2%;
  padding: 0px 40px 0px 40px;
  box-shadow: 0px 9px 30px #fff9c528;
  &::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: #434343;
      background-size: contain;
      border-radius: 20px;
      opacity: 0.4;
    }
}
.Homepagecontent4 {
  position:relative;
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 600px;
  border-radius: 20px;
  margin-top: 2%;
  padding: 0px 40px 0px 40px;
  box-shadow: 0px 9px 30px #fff9c528;
  overflow: hidden;
  display: flex;
  &::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: #434343;
      background-size: contain;
      border-radius: 20px;
      opacity: 0.4;
    }
}
.Homepagecontent5 {
  position:relative;
  display: flex;
  justify-content: center;
  max-width: 80%;
  width: 80%;
  border-radius: 20px;
  margin-top: 2%;
  padding: 0px 40px 0px 40px;
  box-shadow: 0px 9px 30px #fff9c528;
  &::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: #434343;
      background-size: contain;
      border-radius: 20px;
      opacity: 0.4;
    }
}
h1 {
    position: relative;
    font-size: 90px;
    color: #FFF;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    line-height: 110px;
    opacity: 0.9;
    text-transform: uppercase;
    margin-left: 60px;
    text-shadow: 8px 8px 0px #000;
}
@media only screen and (max-width: 768px) {
  h1 {
      font-size: 60px;
      text-align: center;
      line-height: 60px;
      margin: 10px;
    }
  }
h2 {
    position: relative;
    font-size: 36px;
    color: #FFF;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 40px;
    opacity: 0.9;
    filter: drop-shadow(0 1px 3px);
    animation: glitch1 2.6s infinite;
}
h3 {
  position: relative;
  font-size: 40px;
  color: #FFF;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  opacity: 0.9;
  filter: drop-shadow(0 1px 3px);
  animation: glitch1 2.6s infinite;
  text-align: center;
}
h4 {
  color: #FFF9C5;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  letter-spacing: 2px;
}
h5 {
  color: #000;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Raleway', sans-serif;
  text-align: left;
  letter-spacing: 2px;
  margin: 300px 0px 0px 20px;
}
p {
  color: #FFF;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  letter-spacing: 2px;
}
@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }
  7% {
    transform: skew(-0.2deg, -0.3deg);
    opacity: 0.75;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  27% {
    transform: none;
    opacity: 1;
  }
  30% {
    transform: skew(0.2deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    transform: none;
    opacity: 1;
  }
  52% {
    transform: none;
    opacity: 1;
  }
  55% {
    transform: skew(-0.1deg, 0.2deg);
    opacity: 0.75;
  }
  50% {
    transform: none;
    opacity: 1;
  }
  72% {
    transform: none;
    opacity: 1;
  }
  75% {
    transform: skew(0.1deg, 0.3deg);
    opacity: 0.75;
  }
  80% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
.jason {
  height: 310px;
  width: 280px;
  border-radius: 40px;
  filter: grayscale(70%);
}
.oakley {
  height: 300px;
  width: 300px;
  border-radius: 40px;
  filter: grayscale(70%);
}

/*BOX LOAD ANIMATION*/
.animate {
    animation-duration: 0.75s;
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
  }
  .animate.glow {
    animation-name: animate-glow;
    animation-timing-function: ease;
  }
  @keyframes animate-glow {
    0% {
      opacity: 0;
      transform: scale(0.8, 0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  .delay1 {
    animation-delay: 0.2s;
  }
  .delay2 {
    animation-delay: 0.4s;
  }
  .delay3 {
    animation-delay: 0.6s;
  }
  .delay4 {
    animation-delay: 0.8s;
  }
  .delay5 {
    animation-delay: 1s;
  }
  .delay6 {
    animation-delay: 1.2s;
  }
  .delay7 {
    animation-delay: 1.4s;
  }
  .delay8 {
    animation-delay: 1.5s;
  }
  .delay9 {
    animation-delay: 2s;
  }
  .delay10 {
    animation-delay: 2.5s;
  }
  .delay11 {
    animation-delay: 3s;
  }
  .delay12 {
    animation-delay: 3.5s;
  }
  .delay13 {
    animation-delay: 4s;
  }
  .delay14 {
    animation-delay: 4.5s;
  }

  /*TEXT SLIDE*/
  .slide-right {
    width: 100%;
    animation: 1s slide-right 0.5s forwards;
      transform:translateX(-120%);
  }
  @keyframes slide-right {
    to {
      transform:translateX(0);
    }
  }

/*TRANSFORMATION PICTURES*/
.transformationcontainer {
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.containerpic {
  position: relative;
  width: 400px;
  filter: brightness(80%);
}
.transformpic {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 80px;
  cursor: pointer;
}
.overlay {
  position: absolute;
  border-radius: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  opacity: 75%;
  cursor: pointer;
}
.containerpic:hover .overlay {
  height: 100%;
}
.pictext {
  color: white;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}


.privacypolicy {
  padding: 0px 300px 0px 300px;
  margin: 120px 0px 120px 0px;
}

@media only screen and (max-width: 790px) {
  .Homepagecontentcontainer{
      margin: 10% -1% ;
  }
  .para1 {
    padding: 0px 40px 0px 40px;
  }
  .card{
    
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 24px;
}
  h5 {
    margin: 200px 0px 0px 20px;
}
.privacypolicy {
  padding: 0px 50px 0px 50px;
  margin: 120px 0px 120px 0px;
}
.Homepagecontent3 {
  margin-top: 10%;
  padding: 0px 40px 100px 40px;
}
.Homepagecontent4 {
  margin-top: 0%;
}
}