.App-header {
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
nav { 
    background-color: transparent;
    width: 100%;
    z-index: 2;
    margin-top: 80px;
    margin-right: 9%;
    text-align: right;
}
nav li {
	display: inline-block;
	padding: 0 40px;
    margin: 0 15px;
}
nav a {
    position: relative;
    font-family: 'Raleway', sans-serif;
	  text-decoration: none;
    font-weight: normal;
	  color: #FFF;
	  font-size: 18px;
    cursor: pointer;
}
nav a::before,
nav a::after {
  content: '';
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all .35s ease;
  opacity: 0;
}
nav a::before {
  content: '';
  right: -5px;
  top: 1px;
  border-top: 1px solid #FFF9C5;
  border-right: 1px solid #FFF9C5;
  transform: translate(-100%, 50%);
}
nav a:after {
  content: '';
  left: -4px;
  bottom: -2px;
  border-bottom: 1px solid #FFF9C5;
  border-left: 1px solid #FFF9C5;
  transform: translate(100%, -50%)
}
nav a:hover:before,
nav a:hover:after{
  transform: translate(0,0);
  opacity: 1;
}
nav a:hover {
  color: #FFF;
  animation: glitch1 2s infinite;
}

.logoimg {
    height: 90px;
    width: 250px;
    margin-top: 53px;
    margin-left: 60%;
    border-radius: 22px;
    opacity: 0.8;
}
.fbimg {
    height: 25px;
    width: 25px;
    margin-top: 80px;
    margin-left: 160px;
    padding: 10px;
    opacity: 0.7;
}
.fbimg:hover {
    animation: glitch1 2s infinite;
  }
.instaimg {
    height: 25px;
    width: 25px;
    margin-top: 80px;
    padding: 10px;
    opacity: 0.7;
}
.instaimg:hover {
    animation: glitch1 2s infinite;
  }

  /*HAMBURGER MENU*/
  @media only screen and (max-width: 1450px) {
    .navbarnormal {
      display: none;
    }
  }

  @media only screen and (max-width: 790px) {
    .fbimg, .instaimg {
      display: none;
    }
    .logoimg {
      margin: 10% 0%;
    }
  }

