.App-footer {
    background-color: #000;
    padding: 20px;
    margin-top: 5%;
    text-align: center;
  }
  footer p {
    color:#FFF;
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
	  text-decoration: none;
    opacity: 0.8;
  }
  footer a {
    text-decoration: none;
    color:#FFF9C5;
    font-size: 12px;
  }
  .fbimgfooter {
    height: 25px;
    width: 25px;
    padding: 10px;
    opacity: 0.7;
}
.fbimgfooter:hover {
    animation: glitch1 2s infinite;
  }
.instaimgfooter {
    height: 25px;
    width: 25px;
    padding: 10px;
    opacity: 0.7;
}
.instaimgfooter:hover {
    animation: glitch1 2s infinite;
  }
@media only screen and (max-width: 790px) {
  .App-footer {
    margin-top: 20%;
  }
}