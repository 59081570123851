.ContactForm form {
    text-align: center;
    width: 400px;
    color: #434343;
    position: relative;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    padding-top: 50px;
    padding-bottom: 50px;
    opacity: 0.9;
  }
  .ContactForm label {
    color: #FFF;
    font-size: 16px;
    line-height: 8px;
    font-family: 'Raleway', sans-serif;
  }
  .ContactForm input {
    font-weight: 300; 
    width: 100%; 
    font-size: 18px; 
    border: 0px;
    margin-bottom: 8%;
    background-color: #434343;
    border: 1px solid #000;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
  }
  .ContactForm textarea {
    font-weight: 300; 
    width: 100%; 
    font-size: 18px; 
    border: 0px;
    margin-bottom: 8%;
    background-color: #434343;
    border: 1px solid #000;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
  }
  .ContactForm input::placeholder {
    font-size: 14px;
  }
  .ContactForm textarea::placeholder {
    font-size: 14px;
  }
  .maincontent {
    display: flex;
    max-width: 100%;
    position: relative;
}
  .sendbutton {
        position: relative;
        font-family: 'Raleway', sans-serif;
        text-decoration: none;
        font-weight: normal;
        color: #FFF;
        font-size: 18px;
        cursor: pointer;
        background-color: transparent;
        border: none;
    }
    .sendbutton::before,
    .sendbutton::after {
      content: '';
      height: 14px;
      width: 14px;
      position: absolute;
      transition: all .35s ease;
      opacity: 0;
    }
    .sendbutton::before {
      content: '';
      right: -5px;
      top: 1px;
      border-top: 1px solid #FFF9C5;
      border-right: 1px solid #FFF9C5;
      transform: translate(-100%, 50%);
    }
    .sendbutton:after {
      content: '';
      left: -4px;
      bottom: -2px;
      border-bottom: 1px solid #FFF9C5;
      border-left: 1px solid #FFF9C5;
      transform: translate(100%, -50%)
    }
    .sendbutton:hover:before,
    .sendbutton:hover:after{
      transform: translate(0,0);
      opacity: 1;
    }
    .sendbutton:hover {
      color: #FFF;
      animation: glitch1 2s infinite;
    }
    @media only screen and (max-width: 790px) {
      .ContactForm form {
        width: 300px;
      }
}